.main-heading{
    color: black;
    font-size: 1.6rem;
    font-weight: 900;
}

.admission-user-modal-detail-item{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    border-bottom: 1px solid lightgray;
}
.admission-user-modal-detail-item-key h1{
    font-size: 18px;
    font-weight: bolder;
}
.admission-user-modal-detail-value h3{
   
    font-size: 16px;
    font-weight: bold;
}